<template>
    <div id="page-list">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab label="Partner" icon-pack="feather" icon="icon-users">
                <div class="tab-text">
                    <div class="layoutstyles__Container-sfl92e-3 chcHTX"  >
                        <div class="layoutstyles__FixedWidthContainer-sfl92e-4 konvWl">
                            <div class="navbarstyles__NavbarContainer-sc-4bjpr8-0 cNjRLL">
                                <vs-button color="primary"
                                           type="border"
                                           icon-pack="feather"
                                           icon="icon-settings" class="ml-8"
                                           @click="$router.push('/user/organization-info?setting').catch(() => {})">
                                    {{ $t('menuOrganization.keySetting') }}
                                </vs-button>
                                <h1 title="IntFace - Partner Camera"
                                    data-testid="navbar-title"
                                    class="styles__TitleContainer-hnw2e0-0 eZwSqY">

                                    IntFace - Partner Camera</h1>

                                <ul data-testid="navbar-toc" class="styles__TocContainer-hnw2e0-1 jeyXuk">
                                    <li class="styles__TocItem-hnw2e0-2 gKfIyE"><a href="#intro">{{ $t('getDocument.intro') }}</a>
                                    </li>
                                </ul>
                                <a :key="index"
                                   v-for="(item, index) in apiList" :href="'#' + item.id">
                                    <div  data-testid="navbar-accordion" class="styles__AccordionContainer-hnw2e0-3 ljJXuo">
                                        <div :class="index == isHightLight ? 'styles__RequestItemContainer-hnw2e0-10 ghnXNL activesTab activesTabHightLight' : 'styles__RequestItemContainer-hnw2e0-10 ghnXNL activesTab ' "  @click="hightLightTabs(index)">
                                            <div data-testid="navbar-accordion-method"
                                                 :class="'styles__MethodWrapper-hnw2e0-8 ' +  item.method">{{item.method}}
                                            </div>
                                            <a :title="item.title"
                                               data-testid="navbar-accordion-request-title"
                                               class="styles__RequestTitle-hnw2e0-9 cJyzvg">{{ item.title }}</a></div>
                                    </div>
                                </a>

                            </div>
                        </div>
                        <div class="layoutstyles__ContentWrapper-sfl92e-5 ijxKbd">
                            <div class="doc-layoutstyles__LayoutContainer-q6d9p9-0 iJzvFj">
                                <div class="doc-layoutstyles__PrimaryWrapper-q6d9p9-1 hqSUuR">
                                    <div class="collectionstyles__CollectionContainer-nm5878-0 crtHBx">
                                        <div id="intros" class="collectionstyles__CollectionTitle-nm5878-1 fFMevb">IntFace -
                                            Partner Camera
                                        </div>
                                        <div class="collectionstyles__DescriptionWrapper-nm5878-2 iwGyID">
                                            <div
                                                class="markdown-viewerstyles__MarkdownContainer-sc-13o0t2u-0 jAKrax markdown-viewer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="intro"
                                 :key="index"
                                 v-for="(item, index) in apiList"
                                 class="doc-bodystyles__DocBodyContainer-sc-6a86lm-0 gkrVIJ"
                            >
                                <div  :id="item.id" class="doc-layoutstyles__LayoutContainer-q6d9p9-0 iJzvFj" >
                                    <div class="doc-layoutstyles__PrimaryWrapper-q6d9p9-1 hqSUuR">

                                        <div class="entitystyles__EntityContainer-kfteh-0 iqkEVQ">
                                            <div class="styles__TitleContainer-sc-12gd6on-1 dgYxAX"><span
                                                data-entity-type="request-method"
                                                :class="'styles__MethodWrapper-sc-12gd6on-3 ' + item.method +'S'">{{ item.method }}</span>
                                                <p data-entity-type="request-title"
                                                   class="styles__TitleWrapper-sc-12gd6on-2 bykRrC">
                                                    {{ item.title }}</p>
                                            </div>
                                            <div data-entity-type="request-url"
                                                 class="styles__EntityUrlContainer-sc-12gd6on-0 jNXUAq">
                                                {{ item.patch }}
                                            </div>
                                            <div  class="entitystyles__DescriptionWrapper-kfteh-2 cBNZMv"><div class="markdown-viewerstyles__MarkdownContainer-sc-13o0t2u-0 jAKrax markdown-viewer"><p><span>{{item.detail}}</span></p><span>
</span><p><span>{{item.param}}</span></p><span>
</span><ul  v-for="(xltem, indexls) in item.bodyList "  :key="indexls"><span>
</span><li>●  <code><span>{{xltem.label}}</span></code><span>: {{xltem.translate}} </span><br>
                                                <span  v-for="(notetem, indexnote) in xltem.note "  :key="indexnote">
                                         <span v-if="notetem.noteLabel"  class="mr-5"></span> <span v-if="notetem.noteLabel">"</span><code v-if="notetem.noteLabel"><span>{{notetem.noteLabel}}</span></code><span v-if="notetem.noteLabel" >"</span><span v-if="notetem.noteValue">: {{notetem.noteValue}}</span>
                                    </span>

                                            </li>
                                                <span>
</span></ul></div></div>
                                            <div class="request-infostyles__RequestInfoContainer-sc-2jolgl-0 dquHjE">
                                                <div class="simple-tablestyles__SimpleTableContainer-sc-15uyerb-0 bupiTR">
                                                    <div class="simple-tablestyles__Title-sc-15uyerb-1 fWMaKP">HEADERS</div>
                                                    <div class="simple-tablestyles__RequestItemRow-sc-15uyerb-2 iysKKK">
                                                        <div
                                                            class="simple-tablestyles__ItemValue-sc-15uyerb-3 simple-tablestyles__ItemKey-sc-15uyerb-4 hnICJP joEFTm">
                                                            access-key
                                                        </div>
                                                        <div class="simple-tablestyles__ItemValue-sc-15uyerb-3 hnICJP">
                                                            <div>{{item.example.access}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="simple-tablestyles__RequestItemRow-sc-15uyerb-2 iysKKK">
                                                        <div
                                                            class="simple-tablestyles__ItemValue-sc-15uyerb-3 simple-tablestyles__ItemKey-sc-15uyerb-4 hnICJP joEFTm">
                                                            timestamp
                                                        </div>
                                                        <div class="simple-tablestyles__ItemValue-sc-15uyerb-3 hnICJP">
                                                            <div>{{item.example.timeStamp}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="simple-tablestyles__RequestItemRow-sc-15uyerb-2 iysKKK">
                                                        <div
                                                            class="simple-tablestyles__ItemValue-sc-15uyerb-3 simple-tablestyles__ItemKey-sc-15uyerb-4 hnICJP joEFTm">
                                                            checksum
                                                        </div>
                                                        <div class="simple-tablestyles__ItemValue-sc-15uyerb-3 hnICJP">
                                                            <div>{{item.example.checksum}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-entity-type="request-body"
                                                     class="request-info-bodystyles__RequestBodyContainer-sc-159utm6-0 jCHxlk">
                                                    <div
                                                        class="request-info-bodystyles__RequestBodyWrapper-sc-159utm6-1 gdThiZ">
                                                        <div v-if="item.body.bodyName == 'BODY'"
                                                             class="styles__CodeContainer-x8693k-0 kKJlGO documentation-code-default">
                                                            <div class="styles__BodyTitleContainer-x8693k-3 hJlUkl" ><span
                                                                class="styles__BodyTitle-x8693k-1 evgtoB">{{item.body.bodyName}}</span><span
                                                                class="styles__BodyTitleInfo-x8693k-2 djBwlY">raw</span></div>
                                                            <div class="styles__CodeWrapper-x8693k-4 dSXGBm">
                                                                <div
                                                                    class="copy-to-clipboardstyles__CopyToClipboardContainer-pcjjqc-1 dAVRLU">
                                                                    <div
                                                                        class="click-to-expandstyles__ClickToExpandContainer-sc-17vma9t-0 hrlPVy click-to-expand-container">
                                                                        <div

                                                                            class="enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0 zvWwx">
                                                                            <div
                                                                                class="enhanced-codestyles__HighlightCode-sc-1utidae-2 fWwvGx highlight-code"><pre data-entity-type="code-block"
                                                                                                                                                                   class="language-javascript"><span v-if="item.body.bodyOption == 'list'"  class="token punctuation">[</span>
  <span class="token punctuation">{</span><code
                                                                                    class="language-javascript"><span v-for="(xtem, indexs) in item.bodyList "  :key="indexs">
    <span  class="token string">"{{xtem.label}}"</span><span class="token operator">:</span> <span class="token string">"{{xtem.value}}"</span><span
                                                                                    class="token punctuation">,</span></span>
</code>  <span class="token punctuation">}</span>
<span v-if="item.body.bodyOption == 'list'" class="token punctuation">]</span></pre>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="item.body.bodyName == 'PARAMS'" class="simple-tablestyles__SimpleTableContainer-sc-15uyerb-0 bupiTRS">
                                                            <div class="simple-tablestyles__Title-sc-15uyerb-1 fWMaKP">{{item.body.bodyName}}</div>
                                                            <div class="simple-tablestyles__RequestItemRow-sc-15uyerb-2 iysKKK" v-for="(xtem, indexs) in item.bodyList "  :key="indexs">
                                                                <div
                                                                    class="simple-tablestyles__ItemValue-sc-15uyerb-3 simple-tablestyles__ItemKey-sc-15uyerb-4 hnICJP joEFTm">
                                                                    {{xtem.label}}
                                                                </div>
                                                                <div class="simple-tablestyles__ItemValue-sc-15uyerb-3 hnICJP">
                                                                    <div>{{xtem.value}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br><br></div>
                                        </div>
                                    </div>

                                    <div class="doc-layoutstyles__SecondaryWrapper-q6d9p9-2 kPdUuq" >
                                        <div class="entitystyles__EntityExampleContainer-kfteh-1">
                                            <div>
                                                <div data-entity-type="example-request"
                                                     class="styles__ExampleRequestContainer-sc-1gk0rxh-7 ftbNjk">
                                                    <div class="styles__ExampleHeader-sc-1gk0rxh-4 eZFVCL">
                                                        <div class="styles__ExampleTitle-sc-1gk0rxh-1 coCuLw">
                                                            {{ $t('getDocument.example') }}
                                                        </div>
                                                        <div
                                                            class="styles__ExampleName-sc-1gk0rxh-3 dKJIOv">
                                                            {{item.title}}
                                                        </div>
                                                    </div>
                                                    <div class="styles__ExampleCodeSnippetContainer-sc-1gk0rxh-8 ccUrdm">
                                                        <div
                                                            class="copy-to-clipboardstyles__CopyToClipboardContainer-pcjjqc-1 dAVRLU">
                                                            <div
                                                                class="click-to-expandstyles__ClickToExpandContainer-sc-17vma9t-0 hrlPVy click-to-expand-container">
                                                                <div data-entity-type="code-block"
                                                                     class="enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0 gHWRAt">
                                                                    <div v-if="item.body.dataRaw == true"
                                                                         class="enhanced-codestyles__HighlightCode-sc-1utidae-2 fluaKj highlight-code" :id="'myRawData' + index ">
                                                                        <vx-tooltip  class="mt-5"  :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                                                            <feather-icon :style="{color: 'white'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyRawData(index)"/>
                                                                        </vx-tooltip><pre
                                                                        class="language-javascript"><code
                                                                        class="language-javascript">curl <span
                                                                        class="token operator">--</span>location <span
                                                                        class="token operator">--</span>request <span
                                                                        class="token constant">{{item.method}}</span> <span
                                                                        class="token string">'{{item.example.domain}}</span> \
<span
    class="token operator">--</span>header <span class="token string">'access-key: {{ item.example.access }}'</span> \
<span class="token operator">--</span>header <span class="token string">'timestamp: {{ item.example.timeStamp }}'</span> \
<span class="token operator">--</span>header <span class="token string">'checksum: {{ item.example.checksum }}'</span> \
<span class="token operator">--</span>data<span class="token operator">-</span>raw '<span
                                                                            class="token punctuation">{</span><span class="token punctuation" v-for="(alltem, indexall) in item.bodyList "  :key="indexall">
    <span class="token string">"{{ alltem.label }}"</span><span class="token operator">:</span> <span
                                                                            class="token string">"{{ alltem.value }}"</span><span class="token punctuation">,</span></span>
  </code><span class="token punctuation">}</span>'</pre>
                                                                    </div>
                                                                    <div v-if="item.body.dataRaw == false"
                                                                         class="enhanced-codestyles__HighlightCode-sc-1utidae-2 fluaKj highlight-code" :id="'myRawData' + index ">
                                                                        <vx-tooltip  class="mt-5"  :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                                                            <feather-icon :style="{color: 'white'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyRawData(index)"/>
                                                                        </vx-tooltip><pre
                                                                        class="language-javascript"><code
                                                                        class="language-javascript">curl <span
                                                                        class="token operator">--</span>location <span
                                                                        class="token operator">--</span>request <span
                                                                        class="token constant">{{item.method}}</span> <span
                                                                        class="token string">'{{item.example.domain}}</span> \
<span
    class="token operator">--</span>header <span class="token string">'access-key: {{ item.example.access }}'</span> \
<span class="token operator">--</span>header <span class="token string">'timestamp: {{ item.example.timeStamp }}'</span> \
<span class="token operator">--</span>header <span class="token string">'checksum: {{ item.example.checksum }}'</span> \
  </code></pre>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vs-tab>
            <vs-tab label="Webhook" icon-pack="feather" icon="icon-terminal">
                <div class="tab-text">
                    <div class="layoutstyles__Container-sfl92e-3 chcHTX"  >
                        <div class="layoutstyles__FixedWidthContainer-sfl92e-4 konvWl">
                            <div class="navbarstyles__NavbarContainer-sc-4bjpr8-0 cNjRLL">
                                <vs-button color="primary"
                                           type="border"
                                           icon-pack="feather"
                                           icon="icon-settings" class="ml-8"
                                           @click="$router.push('/user/organization-info?setting').catch(() => {})">
                                    {{ $t('menuOrganization.keySetting') }}
                                </vs-button>
                                <h1 title="IntFace - Webhook"
                                    data-testid="navbar-title"
                                    class="styles__TitleContainer-hnw2e0-0 eZwSqY">

                                    IntFace - Webhook</h1>

                                <ul data-testid="navbar-toc" class="styles__TocContainer-hnw2e0-1 jeyXuk">
                                    <li class="styles__TocItem-hnw2e0-2 gKfIyE"><a href="#intro">{{ $t('getDocument.intro') }}</a>
                                    </li>
                                </ul>
                                <a :key="index"
                                   v-for="(item, index) in apiListWebhook" :href="'#' + item.id">
                                    <div  data-testid="navbar-accordion" class="styles__AccordionContainer-hnw2e0-3 ljJXuo">
                                        <div :class="index == isHightLight ? 'styles__RequestItemContainer-hnw2e0-10 ghnXNL activesTab activesTabHightLight' : 'styles__RequestItemContainer-hnw2e0-10 ghnXNL activesTab ' "  @click="hightLightTabs(index)">
                                            <div data-testid="navbar-accordion-method"
                                                 :class="'styles__MethodWrapper-hnw2e0-8 ' +  item.method">{{item.method}}
                                            </div>
                                            <a :title="item.title"
                                               data-testid="navbar-accordion-request-title"
                                               class="styles__RequestTitle-hnw2e0-9 cJyzvg">{{ item.title }}</a></div>
                                    </div>
                                </a>

                            </div>
                        </div>
                        <div class="layoutstyles__ContentWrapper-sfl92e-5 ijxKbd">
                            <div class="doc-layoutstyles__LayoutContainer-q6d9p9-0 iJzvFj">
                                <div class="doc-layoutstyles__PrimaryWrapper-q6d9p9-1 hqSUuR">
                                    <div class="collectionstyles__CollectionContainer-nm5878-0 crtHBx">
                                        <div id="introsm" class="collectionstyles__CollectionTitle-nm5878-1 fFMevb">IntFace - Webhook
                                        </div>
                                        <div class="collectionstyles__DescriptionWrapper-nm5878-2 iwGyID">
                                            <div
                                                class="markdown-viewerstyles__MarkdownContainer-sc-13o0t2u-0 jAKrax markdown-viewer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="introm"
                                 :key="index"
                                 v-for="(item, index) in apiListWebhook"
                                 class="doc-bodystyles__DocBodyContainer-sc-6a86lm-0 gkrVIJ"
                            >
                                <div  :id="item.id" class="doc-layoutstyles__LayoutContainer-q6d9p9-0 iJzvFj" >
                                    <div class="doc-layoutstyles__PrimaryWrapper-q6d9p9-1 hqSUuR">

                                        <div class="entitystyles__EntityContainer-kfteh-0 iqkEVQ">
                                            <div class="styles__TitleContainer-sc-12gd6on-1 dgYxAX"><span
                                                data-entity-type="request-method"
                                                :class="'styles__MethodWrapper-sc-12gd6on-3 ' + item.method +'S'">{{ item.method }}</span>
                                                <p data-entity-type="request-title"
                                                   class="styles__TitleWrapper-sc-12gd6on-2 bykRrC">
                                                    {{ item.title }}</p>
                                            </div>
                                            <div data-entity-type="request-url"
                                                 class="styles__EntityUrlContainer-sc-12gd6on-0 jNXUAq">
                                                {{ item.patch }}
                                            </div>
                                            <div  class="entitystyles__DescriptionWrapper-kfteh-2 cBNZMv"><div class="markdown-viewerstyles__MarkdownContainer-sc-13o0t2u-0 jAKrax markdown-viewer"><p><span>{{item.detail}}</span></p><span>
</span><p><span>{{item.param}}</span></p><span>
</span><ul  v-for="(xltem, indexls) in item.bodyList "  :key="indexls"><span>
</span><li>●  <code><span>{{xltem.label}}</span></code><span>: {{xltem.translate}} </span><br>
                                                <ul  v-for="(notetem, indexnote) in xltem.note "  :key="indexnote">
                                                        <li>
                                                                  <span v-if="notetem.noteLabel"  class="mr-5"></span> <span v-if="notetem.noteLabel">"</span><code v-if="notetem.noteLabel"><span>{{notetem.noteLabel}}</span></code><span v-if="notetem.noteLabel" >"</span><span>: {{notetem.noteValue}}</span>
                                                        </li>

                                    </ul>

                                            </li>
                                                <span>

</span></ul></div></div>

                                            <div class="request-infostyles__RequestInfoContainer-sc-2jolgl-0 dquHjE">

                                                <div data-entity-type="request-body"
                                                     class="request-info-bodystyles__RequestBodyContainer-sc-159utm6-0 jCHxlk">
                                                    <div
                                                        class="request-info-bodystyles__RequestBodyWrapper-sc-159utm6-1 gdThiZ">
                                                        <div v-if="item.body.bodyName == 'BODY'"
                                                             class="styles__CodeContainer-x8693k-0 kKJlGO documentation-code-default">
                                                            <div class="styles__BodyTitleContainer-x8693k-3 hJlUkl" ><span
                                                                class="styles__BodyTitle-x8693k-1 evgtoB">{{item.body.bodyName}}</span><span
                                                                class="styles__BodyTitleInfo-x8693k-2 djBwlY">raw</span></div>
                                                            <div class="styles__CodeWrapper-x8693k-4 dSXGBm">
                                                                <div
                                                                    class="copy-to-clipboardstyles__CopyToClipboardContainer-pcjjqc-1 dAVRLU">
                                                                    <div
                                                                        class="click-to-expandstyles__ClickToExpandContainer-sc-17vma9t-0 hrlPVy click-to-expand-container">
                                                                        <div

                                                                            class="enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0 zvWwx">
                                                                            <div
                                                                                class="enhanced-codestyles__HighlightCode-sc-1utidae-2 fWwvGx highlight-code"><pre data-entity-type="code-block"
                                                                                                                                                                   class="language-javascript"><span v-if="item.body.bodyOption == 'list'"  class="token punctuation">[</span>
  <span class="token punctuation">{</span><code
                                                                                    class="language-javascript"><span v-for="(xtem, indexs) in item.bodyList "  :key="indexs">
    <span  class="token string">"{{xtem.label}}"</span><span class="token operator">:</span> <span class="token string">"{{xtem.value}}"</span><span
                                                                                    class="token punctuation">,</span></span>
</code>  <span class="token punctuation">}</span>
<span v-if="item.body.bodyOption == 'list'" class="token punctuation">]</span></pre>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="item.body.bodyName == 'PARAMS'" class="simple-tablestyles__SimpleTableContainer-sc-15uyerb-0 bupiTRS">
                                                            <div class="simple-tablestyles__Title-sc-15uyerb-1 fWMaKP">{{item.body.bodyName}}</div>
                                                            <div class="simple-tablestyles__RequestItemRow-sc-15uyerb-2 iysKKK" v-for="(xtem, indexs) in item.bodyList "  :key="indexs">
                                                                <div
                                                                    class="simple-tablestyles__ItemValue-sc-15uyerb-3 simple-tablestyles__ItemKey-sc-15uyerb-4 hnICJP joEFTm">
                                                                    {{xtem.label}}
                                                                </div>
                                                                <div class="simple-tablestyles__ItemValue-sc-15uyerb-3 hnICJP">
                                                                    <div>{{xtem.value}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br><br></div>
                                        </div>
                                    </div>

                                    <div class="doc-layoutstyles__SecondaryWrapper-q6d9p9-2 kPdUuq" >
                                        <div class="entitystyles__EntityExampleContainer-kfteh-1">
                                            <div>
                                                <div data-entity-type="example-request"
                                                     class="styles__ExampleRequestContainer-sc-1gk0rxh-7 ftbNjk">
                                                    <div class="styles__ExampleHeader-sc-1gk0rxh-4 eZFVCL">
                                                        <div class="styles__ExampleTitle-sc-1gk0rxh-1 coCuLw">
                                                            {{ $t('getDocument.example') }}
                                                        </div>
                                                        <div
                                                            class="styles__ExampleName-sc-1gk0rxh-3 dKJIOv">
                                                            {{item.title}}
                                                        </div>
                                                    </div>
                                                    <div class="styles__ExampleCodeSnippetContainer-sc-1gk0rxh-8 ccUrdm">
                                                        <div
                                                            class="copy-to-clipboardstyles__CopyToClipboardContainer-pcjjqc-1 dAVRLU">
                                                            <div
                                                                class="click-to-expandstyles__ClickToExpandContainer-sc-17vma9t-0 hrlPVy click-to-expand-container">
                                                                <div data-entity-type="code-block"
                                                                     class="enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0 gHWRAt">
                                                                    <div v-if="item.body.dataRaw == true"
                                                                         class="enhanced-codestyles__HighlightCode-sc-1utidae-2 fluaKj highlight-code" :id="'myRawData' + index ">
                                                                        <vx-tooltip  class="mt-5"  :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                                                            <feather-icon :style="{color: 'white'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyRawData(index)"/>
                                                                        </vx-tooltip><pre
                                                                        class="language-javascript"><code
                                                                        class="language-javascript">curl <span
                                                                        class="token operator">--</span>location <span
                                                                        class="token operator">--</span>request <span
                                                                        class="token constant">{{item.method}}</span> <span
                                                                        class="token string">'{{item.example.domain}}</span> \

<span class="token operator">--</span>data<span class="token operator">-</span>raw '<span
                                                                            class="token punctuation">{</span><span class="token punctuation" v-for="(alltem, indexall) in item.bodyList "  :key="indexall">
    <span class="token string">"{{ alltem.label }}"</span><span class="token operator">:</span> <span
                                                                            class="token string">"{{ alltem.value }}"</span><span class="token punctuation">,</span></span>
  </code><span class="token punctuation">}</span>'</pre>
                                                                    </div>
                                                                    <div v-if="item.body.dataRaw == false"
                                                                         class="enhanced-codestyles__HighlightCode-sc-1utidae-2 fluaKj highlight-code" :id="'myRawData' + index ">
                                                                        <vx-tooltip  class="mt-5"  :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                                                            <feather-icon :style="{color: 'white'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyRawData(index)"/>
                                                                        </vx-tooltip><pre
                                                                        class="language-javascript"><code
                                                                        class="language-javascript">curl <span
                                                                        class="token operator">--</span>location <span
                                                                        class="token operator">--</span>request <span
                                                                        class="token constant">{{item.method}}</span> <span
                                                                        class="token string">'{{item.example.domain}}</span> \
<span
    class="token operator">--</span>header <span class="token string">'access-key: {{ item.example.access }}'</span> \
<span class="token operator">--</span>header <span class="token string">'timestamp: {{ item.example.timeStamp }}'</span> \
<span class="token operator">--</span>header <span class="token string">'checksum: {{ item.example.checksum }}'</span> \
  </code></pre>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vs-tab>
        </vs-tabs>

</div>

</template>

<script>

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'


export default {
    components: {},
    data() {
        return {
            activeTab: 0,
            isHightLight: 0,
            apiList: [
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail1'),
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1a')},
                        {label: 'cameraName', value: 'ba_cam_test_1', translate: this.$t('getDocument.translate1b')},
                        {label: 'link', value: 'rtsp://admin:abc123@pykes01.mlcamera.hv:554/cam/realmonitor?channel=1&subtype=0', translate: this.$t('getDocument.translate1c')},
                        {label: 'packageCode', value: '030', translate: this.$t('getDocument.translate1d')},
                    ],
                    id: 'api/v1/partner/camera/create',
                    method: 'POST', title: this.$t('getDocument.intro1'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'obj',
                        dataRaw: true

                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail2'),
                    id: 'api/v1/partner/camera/update-link-stream',
                    method: 'PUT', title: this.$t('getDocument.intro2'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/update-link-stream',
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1a')},
                        {label: 'link', value: 'rtsp://admin:abc123@pykes01.mlcamera.hv:554/cam/realmonitor?channel=1&subtype=0', translate: this.$t('getDocument.translate1c')},
                    ],
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail3'),
                    id: 'api/v1/partner/camera/update-storage',
                    method: 'PUT', title: this.$t('getDocument.intro3'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/update-storage',
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1c')},
                        {label: 'packageCode', value: '001', translate: this.$t('getDocument.translate1d')},
                    ],
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail4'),
                    id: 'api/v1/partner/camera/delete',
                    method: 'PUT', title: this.$t('getDocument.intro4'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/delete?alias=001',
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1a')},
                    ],
                    body: {
                        bodyName: 'PARAMS',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail5'),
                    id: 'api/v1/partner/camera/disconnect',
                    method: 'PUT', title: this.$t('getDocument.intro5'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/disconnect?alias=001',
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1a')},
                    ],
                    body: {
                        bodyName: 'PARAMS',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail6'),
                    id: 'api/v1/partner/camera/connect',
                    method: 'PUT', title: this.$t('getDocument.intro6'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/connect?alias=001',
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1a')},
                    ],
                    body: {
                        bodyName: 'PARAMS',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail7'),
                    id: 'api/v1/partner/camera/records',
                    method: 'POST', title: this.$t('getDocument.intro7'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/records',
                    bodyList: [
                        {label: 'alias', value: '003' , translate: this.$t('getDocument.translate1a')},
                        {label: 'cameraName', value: 'Cam Binh Minh 1' , translate: this.$t('getDocument.translate1b')},
                        {label: 'endTime', value: '1635955200000', translate: this.$t('getDocument.translate1e')},
                        {label: 'startTime', value: '1635872400000', mtranslate: this.$t('getDocument.translate1f')},
                        {label: 'page', value: '0', translate: this.$t('getDocument.translate1g')},
                        {label: 'pageSize', value: '1000', translate: this.$t('getDocument.translate1h')},
                        {label: 'recordType', value: 'session' , translate: this.$t('getDocument.translate1l'),  note: [{noteLabel: 'session'},{ noteValue: this.$t('getDocument.intro1a')},{noteLabel: 'event'}, {noteValue: this.$t('getDocument.intro1b')}]},
                    ],
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    detail: this.$t('getDocument.detail8'),
                    id: 'api/v1/partner/camera/get-all',
                    method: 'GET', title: this.$t('getDocument.intro8'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/get-all',
                    bodyList: [

                    ],
                    body: {
                        bodyName: '',
                        bodyOption: 'obj',
                        dataRaw: false
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail9'),
                    id: 'api/v1/partner/camera/package-history',
                    method: 'GET', title: this.$t('getDocument.intro9'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/package-history?alias=001',
                    bodyList: [
                        {label: 'alias', value: '001', translate: this.$t('getDocument.translate1a') },
                    ],
                    body: {
                        bodyName: 'PARAMS',
                        bodyOption: 'obj',
                        dataRaw: false
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail10'),
                    id: 'api/v1/partner/camera/create-ftp',
                    method: 'POST', title: this.$t('getDocument.intro10'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/create-ftp',
                    bodyList: [
                        {label: 'alias', value: 'camera1', translate: this.$t('getDocument.translate1a')},
                        {label: 'fileSize', value: '100000', translate: this.$t('getDocument.translate1m')},
                        {label: 'fileName', value: 'file.mp55555555', translate: this.$t('getDocument.translate1n')},
                        {label: 'patch', value: '0911111111', translate: this.$t('getDocument.translate1i')},
                        {label: 'startTime', value: '2022-01-18T09:40:14.477Z', translate: this.$t('getDocument.translate1k')},
                        {label: 'endTime', value: '2022-01-18T09:40:14.477Z', translate: this.$t('getDocument.translate1j')},
                    ],
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'list',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail11'),
                    id: 'api/v1/partner/camera/auto/create-ftp',
                    method: 'POST', title: this.$t('getDocument.intro13'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/auto/create-ftp',
                    bodyList: [
                        {label: 'alias', value: 'camera1', translate: this.$t('getDocument.translate1a')},
                        {label: 'fileSize', value: '100000', translate: this.$t('getDocument.translate1m')},
                        {label: 'fileName', value: 'file.mp5555555', translate:  this.$t('getDocument.translate1n')},
                        {label: 'patch', value: '0911111111', translate:  this.$t('getDocument.translate1i')},
                        {label: 'startTime', value: '2022-11-30T07:39:00.000+0000', translate: this.$t('getDocument.translate1p')},
                        {label: 'endTime', value: '2022-11-30T07:39:00.000+0000', translate: this.$t('getDocument.translate1o')},
                        {label: 'packageCode', value: '001', translate: this.$t('getDocument.translate1d')},
                        {label: 'cameraName', value: 'ba_cam_te', translate: this.$t('getDocument.translate1b')},
                    ],
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'list',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.detail12'),
                    id: 'api/v1/partner/camera/find-by-condition-ftp',
                    method: 'POST', title: this.$t('getDocument.intro11'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/find-by-condition-ftp',
                    bodyList: [
                        {label: 'id', value: '61e69458d7dfeb335561dd8c', translate: this.$t('getDocument.translate1q')},
                        {label: 'alias', value: 'string', translate: this.$t('getDocument.translate1a')},
                        {label: 'endTime', value: '2022-01-18T09:40:14.477Z', translate: this.$t('getDocument.translate1m')},
                        {label: 'fileName', value: 'string', translate:  this.$t('getDocument.translate1n')},
                        {label: 'fileSize', value: 'string', translate:  this.$t('getDocument.translate1i')},
                        {label: 'patch', value: 'string', translate: this.$t('getDocument.translate1p')},
                        {label: 'startTime', value: '2022-01-18T09:40:14.477Z', translate: this.$t('getDocument.translate1o')},
                    ],
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'obj',
                        dataRaw: true
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
                {
                    detail: this.$t('getDocument.detail13'),
                    id: 'api/v1/partner/camera/camera-package/find-all',
                    method: 'GET', title: this.$t('getDocument.intro12'), patch: 'https://api.intface.vn/crm/api/v1/partner/camera/camera-package/find-all',
                    bodyList: [
                    ],
                    body: {
                        bodyName: '',
                        bodyOption: 'obj',
                        dataRaw: false
                    },
                    example: {
                        domain: 'https://api.intface.vn/crm/api/v1/partner/camera/create',
                        access: 'jHBLTg83LMGhStTFi9+Q91HoR4TbG603',
                        timeStamp: '1642561011',
                        checksum: 'f37506451f7248a8ab45fdb6347c07a8d8e59151',
                    }
                },
            ],
            apiListWebhook: [
                {
                    param: 'Param:',
                    detail: this.$t('getDocument.textwh1'),
                    bodyList: [
                        {label: 'cameraStatusEventType', value: 'heat_map_detection', translate: this.$t('getDocument.textwh2') , note: [
                                {noteLabel: 'start_recording', noteValue: this.$t('getDocument.textwh3')},

                                {noteLabel: 'stop_recording', noteValue: this.$t('getDocument.textwh4')},

                                {noteLabel: 'intrusion_detection',noteValue: this.$t('getDocument.textwh5')},

                                {noteLabel: 'face_detection', noteValue: this.$t('getDocument.textwh6')},

                                {noteLabel: 'connect', noteValue: this.$t('getDocument.textwh7')},

                                {noteLabel: 'disconnect', noteValue: this.$t('getDocument.textwh8')},

                                {noteLabel: 'mask_detection', noteValue: this.$t('getDocument.textwh9')},

                                {noteLabel: 'fire_detection', noteValue: this.$t('getDocument.textwh10')},

                                {noteLabel: 'heat_map_detection', noteValue: this.$t('getDocument.textwh11')},
                            ]},
                        {label: 'organizationBranchId', value: '1', translate: this.$t('getDocument.textwh12')},
                        {label: 'organizationId', value: '1', translate: this.$t('getDocument.textwh13')},
                        {label: 'imageUrl', value: 'https://api.intface.vn/processor/api/v1/storage/e40c953c-8625-4a6d-b85d-a8fdaf03b1ba', translate: this.$t('getDocument.textwh14')},
                        {label: 'cameraId', value: '68', translate: this.$t('getDocument.textwh15')},
                        {label: 'cameraName', value: 'Checkin', translate: this.$t('getDocument.textwh16')},
                        {label: 'createdAt', value: '2022-05-11T14:30:07Z', translate: this.$t('getDocument.textwh17')},
                        {label: 'insertedAt', value: '2022-05-11T14:30:07Z', translate: this.$t('getDocument.textwh18')},
                        {label: 'dataAI', value: '[{"dataAI":{"coordinatesList":[{"x":378,"y":193},{"x":379,"y":194},{"x":375,"y":170},{"x":391,"y":191},{"x":379,"y":188},{"x":380,"y":187},{"x":377,"y":192},{"x":382,"y":192},{"x":152,"y":191}],"startDuration":"2022-05-11T14:27:56Z","endDuration":"2022-05-11T14:30:07Z","frameDims":{"width":"640","height":"360"},"organizationId":"1","organizationBranchId":"1","cameraId":"98","cameraName":"Checkin 1","createdAt":"2022-05-11T14:30:07Z","insertAt":"2022-05-11T14:30:07Z"}}]', translate:this.$t('getDocument.textwh19') },
                        {label: 'Alias', value: 'camera 1', translate: this.$t('getDocument.textwh20')},
                        {label: 'numberRevoke', value: '0', translate: this.$t('getDocument.numberRevoke')},

                    ],
                    id: 'api/v1/partner/camera/create',
                    method: 'POST', title: this.$t('getDocument.textwh21') , patch: '{{BASE_URL}}',
                    body: {
                        bodyName: 'BODY',
                        bodyOption: 'obj',
                        dataRaw: true

                    },
                    example: {
                        domain: '{{BASE_URL}}',
                    }
                },
            ],
        }
    },
    created() {
        if (this.$route.fullPath == "/user/api_docs?webhook"){
            this.activeTab = 1
        } else {
            this.activeTab = 0
        }
    },
    watch: {},
    computed: {},
    methods: {
        hightLightTabs(id){
            this.isHightLight = id
        },
        coppyRawData(idx){
            let copyText = document.getElementById("myRawData" + idx);
            copyText.textContent;
            navigator.clipboard.writeText(copyText.textContent);
            return this.$vs.notify({
                text: this.$t('getDocument.textCopyData'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
            })
        },
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>

.cNjRLL {
    margin-bottom: 100px;
}

.eZwSqY {
    color: rgb(33, 33, 33);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    padding: 6px 0px 6px 6px;
    margin: 21px 10px 1px 11px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgb(237, 237, 237);
}

.jeyXuk {
    font-size: 12px;
    line-height: 20px;
    color: rgb(33, 33, 33);
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 0px;
}

.gKfIyE {
    display: block;
    margin-right: 10px;
    margin-left: 11px;
    padding-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(33, 33, 33);
    text-decoration: none;
}

.gKfIyE a {
    color: rgb(33, 33, 33);
}

.ljJXuo {
    padding-left: 0px;
    font-size: 12px;
}

.ghnXNL {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    min-height: 30px;
}

.POST {
    font-size: 9px;
    line-height: 10px;
    font-weight: 700;
    min-width: 30px;
    text-align: right;
    color: rgb(255, 180, 0);
}

.cJyzvg {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(33, 33, 33);
    margin: 0px 5px 0px 10px;
}

.PUT {
    font-size: 9px;
    line-height: 10px;
    font-weight: 700;
    min-width: 30px;
    text-align: center;
    color: rgb(9, 123, 237);
}

.cJyzvg {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(33, 33, 33);
    margin: 0px 5px 0px 10px;
}

.ijxKbd {
    flex: 1 1 0%;
    min-width: 0px;
}

//.gkrVIJ {
//    width: 100%;
//    min-height: calc(110vh);
//}
//@media (min-width: 769px) {
//    .chcHTX {
//        position: static;
//        top: 0px;
//    }
//    .chcHTX {
//        display: flex;
//        max-width: 100%;
//        position: relative;
//        top: 64px;
//    }
//}

//@media (min-width: 992px) {
//    .konvWl {
//        position: sticky;
//        top: 40px;
//    }
//    .konvWl {
//        width: 260px;
//        min-width: 260px;
//        height: 100vh;
//        background-color: whitesmoke;
//        overflow: auto;
//        overscroll-behavior: contain;
//        top: 0px;
//        left: -260px;
//        position: fixed;
//        transition: left 0.1s linear 0s;
//    }
//}

.iJzvFj {
    width: 100%;
}

.iJzvFj::before {
    content: " ";
    display: table;
    height: 40px;
    margin-top: -40px;
}

@media (min-width: 1200px) {
    .hqSUuR {
        width: 1170px;
    }
}

@media (min-width: 992px) {
    .hqSUuR {
        width: 970px;
    }
}

@media (min-width: 768px) {
    .hqSUuR {
        width: 750px;
    }
}

.hqSUuR {
    max-width: 60%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.crtHBx {
    padding: 15px 20px;
}

.fFMevb {
    overflow-wrap: break-word;
    color: rgb(40, 40, 40);
    padding-top: 20px;
    margin: 0px 0px 10px;
    font-weight: 400;
    font-size: 36px;
}

.iwGyID {
    padding: 0px 0px 20px;
    word-break: break-word;
}

.jAKrax {
    color: rgb(128, 128, 128);
}

.iJzvFj::before {
    content: " ";
    display: table;
    height: 40px;
    margin-top: -40px;
}

.gkrVIJ {
    margin-bottom: 60px;
}

.ijxKbd {
    flex: 1 1 0%;
    min-width: 0px;
}

@media (min-width: 769px) {
    .chcHTX {
        position: static;
        top: 0px;
    }
}

.chcHTX {
    display: flex;
    max-width: 100%;
    position: relative;

}

@media (min-width: 992px) {
    .kUyHtq {
        left: 0px;
    }
}

.kUyHtq {
    position: relative;
    width: 100%;
    left: 0px;
    transition: left 0.1s linear 0s;
}


.iqkEVQ {
    padding: 15px 20px;
    border-top: thin solid rgb(236, 236, 236);
}

.POSTS {
    margin-right: 10px;
    font-size: 16px;
    color: rgb(255, 180, 0);
}

.dgYxAX {
    margin: 20px 0px 10px;
    font-weight: 700;
}

.bykRrC {
    display: inline;
    overflow-wrap: break-word;
    color: rgb(40, 40, 40);
    font-size: 16px !important;
}

.jNXUAq {
    display: inline-block;
    word-break: break-all;
    border: 1px solid rgb(230, 230, 230);
    padding: 6px 10px;
    border-radius: 3px;
    font-size: 12px;
    color: rgb(40, 40, 40);
    background-color: rgb(248, 248, 248);
    margin: 5px 0px 15px;
    width: 100%;
}

.bupiTR {
    margin-top: 15px;
}

.fWMaKP {
    height: 24px;
    line-height: 24px;
    font-weight: 700;
    font-size: 14px;
    color: rgb(26, 26, 26);
    border-bottom: 1px solid rgb(236, 236, 236);
}

.iysKKK {
    display: flex;
    word-break: break-word;
}

.bupiTR > * + * {
    margin-top: 15px;
}
.bupiTRS > * + * {
    margin-top: 5px;
}

.joEFTm {
    margin-right: 16px;
    color: rgb(40, 40, 40);
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    padding-right: 10px;
    width: 25%;
}

.hnICJP {
    overflow-wrap: break-word;
    word-break: break-word;
    color: rgb(40, 40, 40);
    line-height: 24px;
    font-weight: 400;
    font-size: 12px;
    width: 75%;
}

.jCHxlk {
    margin-top: 20px;
}

.hJlUkl {
    font-size: 14px;
    line-height: 24px;
    border-bottom: 2px solid rgb(236, 236, 236);
    margin-bottom: 15px;
    font-weight: 600;
}

.evgtoB {
    height: 24px;
    line-height: 24px;
    font-weight: 700;
    font-size: 14px;
    color: rgb(26, 26, 26);
    text-transform: uppercase;
    margin-right: 8px;
}

.djBwlY {
    color: rgb(102, 102, 102);
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.hrlPVy {
    max-width: 100%;
    width: 100%;
    position: relative;
    background-color: transparent;
    overflow: auto hidden;
    border-radius: 2px;
}

//.hrlPVy .enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0 {
//    max-height: calc(198px);
//}

.zvWwx {
    height: 100%;
}

.fWwvGx {
    height: 100%;
}

.zvWwx pre {
    padding: 5px 10px !important;
    background-color: rgb(250, 250, 250) !important;
    border-radius: 3px !important;
    border: thin solid rgb(230, 230, 230) !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-family: Courier, sans-serif !important;
}

.kPdUuq {
    //background: rgb(48, 48, 48);
    padding: 0px 15px 30px;
}

.kPdUuq > div {
    width: 1170px;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
}

@media (min-width: 1200px) {
    .kPdUuq > div {
        width: 1170px;
    }
}

@media (min-width: 992px) {
    .kPdUuq > div {
        width: 970px;
    }
}

@media (min-width: 768px) {
    .kPdUuq > div {
        width: 750px;
    }
}

.eZFVCL {
    padding: 8px 0px;
    display: flex;
}

.coCuLw {
    font-size: 12px;
    //color: rgb(128, 128, 128);
    color: black;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    margin-left: 16px;
    white-space: nowrap;
}

.dKJIOv {
    float: right;
    font-size: 12px;
    color: black;
    line-height: 32px;
    margin-right: 16px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 400px;
    text-align: right;
    flex: 1 1 0%;
    padding-left: 60px;
}

.dAVRLU {
    position: relative;
}

.hJiEhL {
    max-width: 100%;
    width: 100%;
    position: relative;
    background-color: transparent;
    overflow: hidden;
    border-radius: 2px;
}

.cbiUgA {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.gRrQZy {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 120px;
    height: 32px;
    cursor: pointer;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    position: absolute;
    border-radius: 50px;
    text-align: center;
    color: rgb(171, 171, 171);
    background: rgb(70, 70, 70);
}

//.cbiUgA::after {
//    content: " ";
//    position: absolute;
//    height: 30px;
//    width: 100%;
//    right: 0px;
//    left: 0px;
//    bottom: 0px;
//    display: block;
//    background-blend-mode: multiply;
//    background: linear-gradient(
//            0deg, rgba(0, 0, 0, 0.45), rgba(255, 255, 255, 0));
//}

//.hJiEhL .enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0 {
//    max-height: calc(198px);
//}

.gHWRAt {
    height: 100%;
}

.fluaKj {
    height: 100%;
}

.fluaKj:not(pre) > code[class*="language-"], .fluaKj pre[class*="language-"] {
    background: rgb(39, 40, 34);
}

.fluaKj pre[class*="language-"] {
    overflow: auto;
}

.fluaKj code[class*="language-"], .fluaKj pre[class*="language-"] {
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
    color: rgb(248, 248, 248) !important;
}

.fluaKj pre {
    height: 100%;
    margin: 0px !important;
}

.gHWRAt pre {
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    padding: 16px !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    border-radius: 0px !important;
    border: 0px !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.fluaKj .token.property, .fluaKj .token.tag, .fluaKj .token.constant, .fluaKj .token.symbol, .fluaKj .token.deleted {
    color: rgb(249, 38, 114);
}

.fluaKj .token.selector, .fluaKj .token.attr-name, .fluaKj .token.string, .fluaKj .token.char, .fluaKj .token.builtin, .fluaKj .token.inserted {
    color: rgb(166, 226, 46);
}

.cuTAkA {
    background-color: rgb(70, 70, 70);
    border-width: 0px;
    height: 32px;
    width: 32px;
    padding-top: 8px;
    border-radius: 4px;

    position: absolute;
    right: 16px;
    margin-right: 5px;
    top: 10px;
}

button:active, button:focus {
    outline: none !important;
}

.jNXUAq {
    display: inline-block;
    word-break: break-all;
    border: 1px solid rgb(230, 230, 230);
    padding: 6px 10px;
    border-radius: 3px;
    font-size: 12px;
    color: rgb(40, 40, 40);
    background-color: rgb(248, 248, 248);
    margin: 5px 0px 15px;
    width: 100%;
}

.iqkEVQ {
    padding: 15px 20px;
    border-top: thin solid rgb(236, 236, 236);
}

.dgYxAX {
    margin: 20px 0px 10px;
    font-weight: 700;
}

.PUTS {
    margin-right: 10px;
    font-size: 16px;
    color: rgb(9, 123, 237);
}

.dgYxAX {
    margin: 20px 0px 10px;
    font-weight: 700;
}

.bykRrC {
    display: inline;
    overflow-wrap: break-word;
    color: rgb(40, 40, 40);
    font-size: 16px !important;
}

.konvWl {
    width: 260px;
    min-width: 260px;
    height: 100vh;
    background-color: whitesmoke;
    overflow: auto;
    overscroll-behavior: contain;
    top: 0px;
    left: -260px;
    position: fixed;
    transition: left 0.1s linear 0s;
}

@media (min-width: 992px) {
    .konvWl {
        position: sticky;
        top: 40px;
    }

}

.cNjRLL {
    margin-bottom: 100px;
}

.GET {
    font-size: 9px;
    line-height: 10px;
    font-weight: 700;
    min-width: 30px;
    text-align: center;
    color: rgb(12, 187, 82);
}

.kPdUuq > div {
    width: 1170px;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
}

.hJiEhL .enhanced-codestyles__EnhancedCodeContainer-sc-1utidae-0[data-v-a67ce186] {
    max-height: 400px !important;
}

.GETS {
    margin-right: 10px;
    font-size: 16px;
    color: rgb(12, 187, 82);
}

.activesTab:hover {
    background: linear-gradient(to right, #ffffff 0%, #19b179 60%);
}

.activesTab:active {
    background: #19b179;
}
.activesTabHightLight {
    background: linear-gradient(to right, #ffffff 0%, #19b179 60%);
    -webkit-transition: all 0.1s;
    border-color: transparent;
}
.cBNZMv {
    padding: 0px 0px 20px;
    word-break: break-word;
}
.jAKrax {
    color: rgb(128, 128, 128);
}
.jAKrax p {
    color: rgb(40, 40, 40);
    font-weight: normal;
    font-size: 14px;
    margin: 0px 0px 10px;
}
.jAKrax ul, .jAKrax ol, .jAKrax dl {
    color: rgb(40, 40, 40);
    padding-left: 18px;
}
.jAKrax code {
    color: grey;
    border-radius: 3px;
    border: thin solid rgb(230, 230, 230);
    font-family: Courier, sans-serif;
    background: transparent;
}

</style>
